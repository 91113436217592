/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { makeStyles, useTheme } from '@material-ui/styles';
import { MdModeNight } from 'react-icons/md';
import { WiDaySunny } from 'react-icons/wi';
import { IconButton } from '@mui/material';
import Layout from '../../components/layout';
import BackgroundImage from '../../assets/images/default-background.svg';
import BiziIcon from '../../assets/images/bizi_icon.svg';
import config from '../../config';
import ColorModeContext from '../../config/color-context';

export default function Default() {
  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);

  const backgroundTheme = theme.palette.background.default;
  const textTheme = theme.palette.text.default;

  const useStyles = makeStyles(() => ({
    root: {
      paddingTop: '1.5rem',
      paddingBottom: ['1.5rem', '2.5rem'],
    },
    grid: {
      gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
      '@media (min-width: 1024px)': { display: 'grid' },
    },
    columnOne: {
      position: 'relative',
      gridColumn: 'span 1 / span 1',
      borderTopLeftRadius: '1rem',
      borderTopRightRadius: '1rem',
      width: '100%',
      height: '220px',
      '@media (min-width: 1024px)': {
        borderTopLeftRadius: '1rem',
        borderBottomLeftRadius: '1rem',
        borderTopRightRadius: 'unset !important',
        height: '280px',
      },
    },
    img: {
      borderTopLeftRadius: '1rem',
      borderTopRightRadius: '1rem',
      width: '100%',
      height: '100%',
      '@media (min-width: 1024px)': {
        borderTopLeftRadius: '1rem',
        borderBottomLeftRadius: '1rem',
        borderTopRightRadius: 'unset !important',
      },
    },
    gridOneColumnTwo: {
      display: 'flex',
      position: 'relative',
      padding: '1.5rem',
      flexDirection: 'column',
      gridColumn: 'span 2 / span 2',
      borderBottomRightRadius: '1rem',
      borderBottomLeftRadius: '1rem',
      backgroundColor: '#ffffff',
      '@media (min-width: 1024px)': {
        borderTopRightRadius: '1rem',
        borderBottomRightRadius: '1rem',
        borderBottomLeftRadius: '0rem',
      },
    },
    header: {
      marginTop: '0.25rem',
      fontSize: '1.25rem',
      lineHeight: ['1.75rem', '1.5rem'],
      fontWeight: 500,
      color: '#570435',
    },
    paragraph: {
      marginTop: '1rem',
      fontSize: '1rem',
      lineHeight: ['1.5rem', '1.5rem'],
      color: '#4B5563',
    },
    link: {
      display: 'inline-flex',
      paddingTop: '0.5rem',
      paddingBottom: '0.5rem',
      paddingLeft: '1.5rem',
      paddingRight: '1.5rem',
      marginTop: '1.25rem',
      alignItems: 'center',
      borderRadius: '0.375rem',
      boxShadow: [
        'var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color)',
        '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
      ],
      '--tw-ring-inset': 'inset',
      width: 'fit-content',
      fontSize: '1.125rem',
      lineHeight: '1.75rem',
      fontWeight: 500,
      textTransform: 'uppercase',
      background: '#FAE7F2',
      color: '#570435',
      '@media (min-width: 1024px)': {
        '&:hover': { background: '#FAE7F2' },
      },
    },
    gridTwo: {
      marginTop: '2rem',
      gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
      '@media (min-width: 1024px)': { display: 'grid' },
    },
    gridTwoColumnOne: {
      display: 'flex',
      position: 'relative',
      gridColumn: 'span 1 / span 1',
      borderTopLeftRadius: '1rem',
      borderTopRightRadius: '1rem',
      width: '100%',
      backgroundColor: '#ffffff',
      height: '220px',
      '@media (min-width: 1024px)': {
        borderTopLeftRadius: '1rem',
        borderTopRightRadius: '0rem',
        borderBottomLeftRadius: '1rem',
        height: '280px',
      },
    },
    imgContainer: {
      position: 'relative',
      width: '100%',
      height: '80px',
      marginTop: 'auto',
      marginBottom: 'auto',
      '@media (min-width: 1024px)': { height: '120px' },
    },
    background: {
      background: backgroundTheme,
    },
  }));

  const classes = useStyles();

  const layoutConfig = {
    defaultPageHandling: true,
    mainClassName: classes.background,
  };

  return (
    <Layout {...layoutConfig}>
      <IconButton
        sx={{
          display: 'none',
          alignItems: 'center',
          cursor: 'pointer',
          position: 'absolute',
          top: '1rem',
          right: '1rem',
          border: `1px solid ${textTheme}`,
          zIndex: 10,
          backdropFilter: 'blur(8px)',
          '@media (min-width: 540px)': {
            display: 'inline-flex',
          },
        }}
        onClick={colorMode.toggleColorMode}
        color="inherit"
      >
        {theme.palette.mode === 'dark' ? (
          <MdModeNight color={textTheme} />
        ) : (
          <WiDaySunny color={textTheme} />
        )}
      </IconButton>
      <div className={classes.root}>
        <div className={classes.grid}>
          <div className={classes.columnOne}>
            <img
              className={classes.img}
              src={BackgroundImage}
              style={{ objectFit: 'cover' }}
              alt="Create Microsites"
            />
          </div>
          <div className={classes.gridOneColumnTwo}>
            <h3 className={classes.header}>Create Microsites</h3>
            <p className={classes.paragraph}>
              Do you want to create microsites? Easily share content, calendar
              invites, or even coupons through all messaging channels.
            </p>
            <a
              href={`https://${config.MARKETING_URL}`}
              className={classes.link}
              target="_blank"
              rel="noreferrer"
            >
              learn more
            </a>
          </div>
        </div>
        <div className={classes.gridTwo}>
          <div className={classes.gridTwoColumnOne}>
            <div className={classes.imgContainer}>
              <img
                className={classes.img}
                src={BiziIcon}
                style={{ objectFit: 'contain' }}
                alt="What is Bizi"
              />
            </div>
          </div>
          <div className={classes.gridOneColumnTwo}>
            <h3 className={classes.header}>What is Bizi</h3>
            <p className={classes.paragraph}>
              Bizi is the premier digital word-of-mouth messaging platform for
              team syndicated content.
            </p>
            <a
              href={`https://${config.MARKETING_URL}`}
              className={classes.link}
              target="_blank"
              rel="noreferrer"
            >
              learn more
            </a>
          </div>
        </div>
      </div>
    </Layout>
  );
}
