/* eslint-disable dot-notation */
/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import configUrl from '../config/configUrl';

const baseURL = configUrl.PUBLIC_API_URL;

export const axiosClient = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosClient.interceptors.request.use(async (config) => {
  let token;
  if (typeof window !== 'undefined') {
    token = localStorage.getItem('token');
  }

  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  return config;
});
