/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { BsArrowLeftCircleFill, BsArrowRightCircleFill } from 'react-icons/bs';
import { makeStyles } from '@material-ui/styles';
import { useSwipeable } from 'react-swipeable';
import CustomContainer from '../../reuseable/CustomContainer';
import CustomImage from '../../reuseable/CustomImage';
import CustomButton from '../../reuseable/CustomButton';
import CustomTitle from '../../reuseable/CustomTitle';
import {
  buttonConfig,
  carouselConfig,
  containerConfig,
  titleConfig,
} from '../../propsConfig';

export default function Carousel({
  imageChild,
  titleChild,
  buttonChild,
  containerClassName,
  containerStyling,
  buttonsDirection,
}) {
  const [slide, setSlide] = React.useState(0);

  const imageContainerConfig = {
    slide,
    setSlide,
    imageChild,
  };

  return (
    <CustomContainer {...containerConfig(containerClassName, containerStyling)}>
      <ImageCarouselContainer {...imageContainerConfig}>
        {carouselConfig(imageChild, slide)?.map((imageBody) => (
          <CustomImage {...imageBody} />
        ))}
      </ImageCarouselContainer>
      <CustomTitle {...titleConfig(titleChild)} />
      {buttonConfig(buttonChild, buttonsDirection)?.map((buttonBody) => (
        <CustomButton {...buttonBody} />
      ))}
    </CustomContainer>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '210px',
    marginLeft: 'auto',
    marginRight: 'auto',
    '@media (min-width: 540px)': { height: '157px' },
  },
  icon: {
    display: 'none',
    position: 'absolute',
    width: '1.5rem',
    height: '1.5rem',
    color: 'white',
    bottom: '5%',
    fill: 'white',
    filter: 'drop-shadow(0 0 2px #555)',
    '&:hover': { cursor: 'pointer' },
    '@media (min-width: 540px)': {
      display: 'flex',
      width: 'calc(1.5rem * 0.75)',
      height: 'calc(1.5rem * 0.75)',
    },
  },
  left5: {
    left: '5%',
  },
  right5: {
    right: '5%',
  },
  btnContainer: { display: 'flex', position: 'absolute', bottom: '1rem' },
  btn: {
    marginTop: '0',
    marginBottom: '0',
    borderRadius: '9999px',
    width: '0.625rem',
    height: '0.625rem',
    cursor: 'pointer',
    marginLeft: '0.2rem',
    marginRight: '0.2rem',
    borderColor: 'none',
    filter: 'drop-shadow(0 0 2px #555)',
    '@media (min-width: 540px)': {
      width: 'calc(0.625rem * 0.75)',
      height: 'calc(0.625rem * 0.75)',
    },
  },
  bgTheme: { background: '#CE0A7D' },
  bgGray: { background: '#D9D9D9' },
}));

export const ImageCarouselContainer = ({
  slide,
  setSlide,
  imageChild,
  children,
}) => {
  const classes = useStyles();

  const nextSlide = () => {
    setSlide(slide === imageChild.length - 1 ? slide : slide + 1);
  };

  const prevSlide = () => {
    setSlide(slide === 0 ? slide : slide - 1);
  };

  const handlers = useSwipeable({
    onSwipedLeft: nextSlide,
    onSwipedRight: prevSlide,
  });

  const myRef = React.useRef();

  const refPassthrough = (el) => {
    handlers.ref(el);
    myRef.current = el;
  };

  return (
    <div {...handlers} ref={refPassthrough} className={classes.root}>
      {children}
      <BsArrowLeftCircleFill
        onClick={prevSlide}
        className={`${classes.icon} ${classes.left5}`}
      />
      <BsArrowRightCircleFill
        onClick={nextSlide}
        className={`${classes.icon} ${classes.right5}`}
      />
      <div className={classes.btnContainer}>
        {imageChild.map((_, idx) => (
          <div
            key={_}
            className={`${classes.btn} ${
              slide === idx ? classes.bgTheme : classes.bgGray
            } `}
            onClick={() => setSlide(idx)}
          />
        ))}
      </div>
    </div>
  );
};
