/* eslint-disable react/button-has-type */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { makeStyles } from '@material-ui/styles';
import Loading from '../../components/loading/Loading';
import { fetchMicrosite } from '../../api/microsite';
import ContentLayout from '../../components/layout/ContentLayout';
import { moveAlphaToEnd } from '../../utils/hexColorFormat';
import Social from '../../components/microsite/elements/Social';
import {
  Button,
  ButtonContainer,
} from '../../components/microsite/elements/Button';
import BiziBioCard from '../../components/microsite/elements/BiziBioCard';
import Info from '../../components/microsite/elements/Info';
import Image from '../../components/microsite/elements/Image';
import Carousel from '../../components/microsite/elements/Carousel';
import Text from '../../components/microsite/elements/Text';
import BusinessCard from '../../components/microsite/elements/BusinessCard';
import Sound from '../../components/microsite/elements/Sound';
import Video from '../../components/microsite/elements/Video';
import Pdf from '../../components/microsite/elements/Pdf';
import Location from '../../components/microsite/elements/Location';
import PageNotFound from '../404';
import ErrorPage from '../error/Error';

export default function BiziMicrosite({ pathnameUrl, protocol, host }) {
  const vanityName = host.split('.')[0];

  const { isLoading, isError, data, error } = useQuery({
    queryKey: ['microsite', pathnameUrl],
    queryFn: async () => {
      const dataBody = await fetchMicrosite(pathnameUrl, vanityName);
      return dataBody.result;
    },
    enabled: Boolean(pathnameUrl),
    refetchOnWindowFocus: false,
  });

  const useStyles = makeStyles(() => ({
    root: {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: '32rem',
      position: 'relative',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(100% - 55px)',
      padding: '20px',
      paddingBottom: '30px',
      gap: '24px',
      overflowY: 'auto',
      scrollBehavior: 'smooth',
      backgroundColor: moveAlphaToEnd(data?.style?.background_color || ''),
      '@media (min-width: 540px)': {
        padding: 'calc(20px * 0.6)',
        paddingBottom: 'calc(30px * 0.6)',
        gap: 'calc(24px * 0.75)',
        height: '600px',
      },
    },
    header: {
      position: 'sticky',
      top: '-1px',
      background: '#ffffff',
      zIndex: 20,
      width: '100%',
      height: '55px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      boxShadow:
        '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
      '@media (min-width: 540px)': {
        height: 'calc(55px * 0.70)',
      },
    },
    headerTitle: {
      fontSize: '1rem',
      lineHeight: '1.2rem',
      letterSpacing: '-0.025em',
      textAlign: 'center',
    },
  }));

  const classes = useStyles();

  if (isError) {
    if (error.message.includes('404')) {
      return <PageNotFound />;
    }
    return <ErrorPage error={error} />;
  }

  if (data && data.status === 'inactive') {
    const url = data.redemption_url.split('//')[1];
    const urlPath = url.split('.')[0];
    if (data.redirect_to_bizi_link) {
      return window.location.replace(`${protocol}//${urlPath}.${host}`);
    }
    return window.location.replace('https://getbizi.io');
  }

  const layoutConfig = {
    className: '',
    biziTitle: data?.title,
  };

  return (
    <ContentLayout>
      {isLoading ? (
        <Loading />
      ) : (
        data && (
          <div className={classes.root}>
            <header className={classes.header}>
              <div className={classes.headerTitle}>
                {layoutConfig.biziTitle ? layoutConfig.biziTitle : ''}
              </div>
            </header>
            <div className={classes.container}>
              {data?.elements.map((elementsBody, idx) => {
                const key = elementsBody.id || idx;
                switch (elementsBody.type) {
                  case 'bizi_bio':
                    return (
                      <BiziBioCard
                        key={key}
                        bioChild={elementsBody?.bizi_bio}
                        containerStyling={{
                          backgroundColor: elementsBody.style?.background_color,
                        }}
                      />
                    );
                  case 'business_card':
                    return (
                      <BusinessCard
                        key={key}
                        cardChild={elementsBody?.business_card}
                        containerStyling={{
                          backgroundColor: elementsBody.style?.background_color,
                        }}
                      />
                    );
                  case 'text':
                    return (
                      <Text
                        key={key}
                        title={elementsBody.element_title?.value}
                        titleStyling={{
                          alignment:
                            elementsBody.element_title?.style?.alignment,
                          color: elementsBody.element_title?.style?.color,
                          fontWeight: elementsBody.element_title?.style?.weight,
                          fontSize: elementsBody.element_title?.style?.size,
                          isItalic: elementsBody.element_title?.style?.italic,
                          isUnderline:
                            elementsBody.element_title?.style?.underline,
                        }}
                        descr={elementsBody.element_description?.value}
                        descrStyling={{
                          alignment:
                            elementsBody.element_description?.style?.alignment,
                          color: elementsBody.element_description?.style?.color,
                          fontWeight:
                            elementsBody.element_description?.style?.weight,
                          fontSize:
                            elementsBody.element_description?.style?.size,
                          isItalic:
                            elementsBody.element_description?.style?.italic,
                          isUnderline:
                            elementsBody.element_description?.style?.underline,
                        }}
                        containerStyling={{
                          backgroundColor: elementsBody.style?.background_color,
                        }}
                      />
                    );
                  case 'social':
                    return (
                      <Social key={key} buttonChild={elementsBody.socials} />
                    );
                  case 'location':
                    return (
                      <Location
                        key={key}
                        mapChild={elementsBody?.geo_location}
                        titleChild={elementsBody?.element_title}
                        buttonChild={elementsBody?.action_buttons}
                        containerStyling={{
                          backgroundColor: elementsBody.style?.background_color,
                        }}
                      />
                    );
                  case 'pdf':
                    return (
                      <Pdf
                        key={key}
                        pdfChild={elementsBody?.medias}
                        titleChild={elementsBody?.element_title}
                        buttonChild={elementsBody?.action_buttons}
                        containerStyling={{
                          backgroundColor: elementsBody.style?.background_color,
                        }}
                      />
                    );
                  case 'button':
                    return (
                      <ButtonContainer
                        key={key}
                        buttonContainerStyling={{
                          backgroundColor: elementsBody.style?.background_color,
                        }}
                      >
                        <Button buttonChild={elementsBody?.action_buttons} />
                      </ButtonContainer>
                    );
                  case 'sound':
                    return (
                      <Sound
                        key={key}
                        soundChild={elementsBody?.medias}
                        titleChild={elementsBody?.element_title}
                        buttonChild={elementsBody?.action_buttons}
                        containerStyling={{
                          backgroundColor: elementsBody.style?.background_color,
                        }}
                      />
                    );
                  case 'video':
                    return (
                      <Video
                        key={key}
                        videoChild={elementsBody?.medias}
                        titleChild={elementsBody?.element_title}
                        textChild={elementsBody?.element_description}
                        buttonChild={elementsBody?.action_buttons}
                        containerStyling={{
                          backgroundColor: elementsBody.style?.background_color,
                        }}
                      />
                    );
                  case 'carousel':
                    return (
                      <Carousel
                        key={key}
                        imageChild={elementsBody?.medias}
                        titleChild={elementsBody?.element_title}
                        buttonChild={elementsBody?.action_buttons}
                        containerStyling={{
                          backgroundColor: elementsBody.style?.background_color,
                        }}
                      />
                    );
                  case 'image':
                    return (
                      <Image
                        key={key}
                        imageChild={elementsBody?.medias}
                        titleChild={elementsBody?.element_title}
                        buttonChild={elementsBody?.action_buttons}
                        containerStyling={{
                          backgroundColor: elementsBody.style?.background_color,
                        }}
                      />
                    );
                  case 'info':
                    return (
                      <Info
                        key={key}
                        imageChild={elementsBody?.medias}
                        titleChild={elementsBody?.element_title}
                        textChild={elementsBody?.element_description}
                        buttonsDirection={elementsBody?.buttons_direction}
                        buttonChild={elementsBody?.action_buttons}
                        containerStyling={{
                          backgroundColor: elementsBody.style?.background_color,
                        }}
                      />
                    );
                  default:
                    return <div />;
                }
              })}
            </div>
          </div>
        )
      )}
    </ContentLayout>
  );
}
