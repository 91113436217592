/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable camelcase */
import { makeStyles } from '@material-ui/styles';
import Business_Card_Icon from '../../assets/icons/business_card.svg';
import Facebook_Icon from '../../assets/icons/facebook.svg';
import Instagram_Icon from '../../assets/icons/instagram.svg';
import Twitter_Icon from '../../assets/icons/twitter.svg';
import Whatsapp_Icon from '../../assets/icons/whatsapp.svg';
import Telegram_Icon from '../../assets/icons/telegram.svg';
import Email_Icon from '../../assets/icons/email.svg';
import Linkedin_Icon from '../../assets/icons/linkedin.svg';
import Youtube_Icon from '../../assets/icons/youtube.svg';
import {
  CallIcon,
  ChatIcon,
  EmailIcon,
  LocationIcon,
  SendIcon,
  UrlIcon,
} from '../icons/ReactIcons';

const useStyles = makeStyles(() => ({
  root: {
    height: '100vh',
    position: 'relative',
  },
  icon: {
    height: '100%',
    width: '100%',
    // '@media (min-width: 540px)': { display: 'flex' },
  },
}));

export function useIconSelector(provider) {
  switch (provider) {
    case 'business card':
      return Business_Card_Icon;
    case 'facebook':
      return Facebook_Icon;
    case 'instagram':
      return Instagram_Icon;
    case 'x':
      return Twitter_Icon;
    case 'whatsapp':
      return Whatsapp_Icon;
    case 'telegram':
      return Telegram_Icon;
    case 'email':
      return Email_Icon;
    case 'linkedin':
      return Linkedin_Icon;
    case 'youtube':
      return Youtube_Icon;
    default:
      return provider;
  }
}

export function useButtonIconSelector(provider, color) {
  const classes = useStyles();

  switch (provider) {
    case 'email':
      return <EmailIcon className={classes.icon} color={color} />;
    case 'phone':
      return <CallIcon className={classes.icon} color={color} />;
    case 'location':
      return <LocationIcon className={classes.icon} color={color} />;
    case 'send':
      return <SendIcon className={classes.icon} color={color} />;
    case 'message':
      return <ChatIcon className={classes.icon} color={color} />;
    case 'url':
      return <UrlIcon className={classes.icon} color={color} />;
    default:
      return <div className="hidden" />;
  }
}
