import React from 'react';
import { makeStyles } from '@material-ui/styles';
import BiziIcon from '../../assets/images/bizi_icon.svg';
import SEDrawer from '../navigation';

export default function Layout({
  children,
  className,
  mainClassName,
  biziTitle,
  defaultPageHandling,
  setHeader,
}) {
  const useStyles = makeStyles(() => ({
    root: {
      position: 'relative',
      minHeight: '100%',
      padding: 0,
      margin: 0,
    },
    header: {
      position: 'fixed',
      zIndex: 10,
      width: '100vw',
      height: '3rem',
      backgroundColor: '#ffffff',
      boxShadow:
        '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    },
    leftSectioncontainer: {
      display: 'flex',
      paddingLeft: '16px',
      paddingRight: '16px',
      alignItems: 'center',
      height: '100%',
      '@media (min-width: 640px)': {
        paddingLeft: '1.5rem',
        paddingRight: '1.5rem',
      },
      '@media (min-width: 1024px)': {
        paddingLeft: '2rem',
        paddingRight: '2rem',
      },
    },
    flex: {
      display: 'flex',
    },
    imgContainer: {
      position: 'relative',
      marginLeft: '0.5rem',
      marginRight: '0.5rem',
      width: '4rem',
      height: '2.5rem',
    },
    img: { width: '100%', height: '100%' },
    headerTitle: {
      marginLeft: 'auto',
      marginRight: 'auto',
      fontSize: '1.25rem',
      lineHeight: '1.75rem',
      fontWeight: 400,
      letterSpacing: '-0.025em',
      color: '#1a0210',
    },
    main: {
      paddingTop: setHeader && '3rem',
      // backgroundColor: '#E5E7EB',
      height: '100vh',
    },
    overflowScroll: { overflowY: 'scroll' },
    overflowHidden: { overflowY: 'hidden' },
    heightFull: { height: '100%' },
    isDefaultHandlingChildContainer: {
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingLeft: '1.5rem',
      paddingRight: '1.5rem',
      maxWidth: '32rem',
      '@media (min-width: 1024px)': {
        paddingLeft: '2rem',
        paddingRight: '2rem',
        maxWidth: '72rem',
      },
    },
  }));

  const classes = useStyles();

  const [openDrawer, setOpenDrawer] = React.useState(false);

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  return (
    <div className={classes.root}>
      {setHeader && (
        <header className={classes.header}>
          <div className={classes.leftSectioncontainer}>
            <div className={classes.flex}>
              <div className={classes.imgContainer}>
                <img
                  className={classes.img}
                  src={BiziIcon}
                  style={{ objectFit: 'contain' }}
                  alt="bizi icon"
                />
              </div>
            </div>
            <h4 className={classes.headerTitle}>
              {biziTitle ? biziTitle.toUpperCase() : 'Bizi Redemption'}
            </h4>
          </div>
        </header>
      )}
      {setHeader && (
        <SEDrawer open={openDrawer} handleClose={handleDrawerClose} />
      )}
      <main
        className={`${classes.main} ${mainClassName}
          ${
            defaultPageHandling
              ? classes.overflowScroll
              : classes.overflowHidden
          }`}
      >
        <div
          className={` ${classes.heightFull}
            ${defaultPageHandling && classes.isDefaultHandlingChildContainer}
            ${className}`}
        >
          {children}
        </div>
      </main>
    </div>
  );
}
