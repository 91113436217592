/* eslint-disable import/prefer-default-export */
import { axiosClient } from './client';

export const fetchBusinessCard = async (id, vanityName) => {
  const response = await axiosClient.get(
    `/business-card/${id}?vanity=${vanityName}`,
  );
  const result = response.data;

  return result;
};
