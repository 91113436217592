import React, { Suspense } from 'react';
import { QueryClient } from '@tanstack/react-query';
import BiziMicrosite from './BiziMicrosite';
import Loading from '../../components/loading/Loading';
import { fetchMicrosite } from '../../api/microsite';

export default function Microsite() {
  const { pathname, protocol, host } = window.location;
  const pathnameUrl = pathname.split('/')[1];
  const vanityName = host.split('.')[0];

  const queryClient = new QueryClient();

  queryClient.prefetchQuery({
    queryKey: ['microsite', pathnameUrl],
    queryFn: async () => {
      const data = await fetchMicrosite(pathnameUrl, vanityName);
      return data.result;
    },
  });

  return (
    <Suspense fallback={<Loading />}>
      <BiziMicrosite {...{ pathnameUrl, protocol, host }} />
    </Suspense>
  );
}
