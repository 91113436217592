/* eslint-disable react/button-has-type */
import React, { startTransition, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import Layout from '../../components/layout';

const useStyles = makeStyles(() => ({
  root: {
    display: 'grid',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    paddingTop: '6rem',
    paddingTottom: '6rem',
    placeItems: 'center',
    minHeight: '100%',
    backgroundColor: '#ffffff',

    '@media (min-width: 640px)': {
      paddingTop: '8rem',
      paddingTottom: '8rem',
    },

    '@media (min-width: 1024px)': {
      paddingLeft: '2rem',
      paddingRight: '2rem',
    },
  },
  textCenter: {
    textAlign: 'center',
  },
  paragraphTag: {
    fontSize: '1.125rem',
    lineHeight: '1.75rem',
    fontWeight: 600,
    color: '#4F46E5',
  },
  headerTag: {
    marginTop: '1rem',
    fontSize: '1.875rem',
    lineHeight: '2.25rem',
    fontWeight: 700,
    letterSpacing: '-0.025em',
    color: '#111827',
    '@media (min-width: 640px)': { fontSize: '3rem', lineHeight: 1 },
  },
  btnContainer: {
    display: 'flex',
    marginTop: '2.5rem',
    columnGap: '1.5rem',
    justifyContent: 'center',
    alignItems: 'center',
  },
  btn: {
    paddingTop: '0.625rem',
    paddingBottom: '0.625rem',
    paddingLeft: '0.875rem',
    paddingRight: '0.875rem',
    borderRadius: '0.375rem',
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    fontWeight: 600,
    color: '#ffffff',
    backgroundColor: '#4F46E5',
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
    '&:hover': { backgroundColor: '#6366F1' },
  },
}));

export default function ErrorPage({ error }) {
  const classes = useStyles();

  function refreshAndReset() {
    startTransition(() => {
      window.location.reload();
    });
  }

  useEffect(() => {
    console.error(error);
  }, [error]);

  return (
    <Layout>
      <main className={classes.root}>
        <div className={classes.textCenter}>
          <p className={classes.paragraphTag}>Something went wrong!</p>
          <h1 className={classes.headerTag}>
            {error.message && 'Error! Please Reload Page'}
          </h1>
          <div className={classes.btnContainer}>
            <button className={classes.btn} onClick={refreshAndReset}>
              Try again
            </button>
          </div>
        </div>
      </main>
    </Layout>
  );
}
