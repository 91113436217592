/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import config from '../../config';
import Layout from '../../components/layout';

const useStyles = makeStyles(() => ({
  root: {
    display: 'grid',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    paddingTop: '6rem',
    paddingTottom: '6rem',
    placeItems: 'center',
    minHeight: '100%',
    backgroundColor: '#ffffff',

    '@media (min-width: 640px)': {
      paddingTop: '8rem',
      paddingTottom: '8rem',
    },

    '@media (min-width: 1024px)': {
      paddingLeft: '2rem',
      paddingRight: '2rem',
    },
  },
  textCenter: {
    textAlign: 'center',
  },
  paragraphTag: {
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontWeight: 600,
    color: '#4F46E5',
  },
  paragraphTwoTag: {
    marginTop: '1.5rem',
    fontSize: '1rem',
    lineHeight: ['1.5rem', '1.75rem'],
    color: '#4B5563',
  },
  headerTag: {
    marginTop: '1rem',
    fontSize: '1.875rem',
    lineHeight: '2.25rem',
    fontWeight: 700,
    letterSpacing: '-0.025em',
    color: '#111827',
    '@media (min-width: 640px)': { fontSize: '3rem', lineHeight: 1 },
  },
  btnContainer: {
    display: 'flex',
    marginTop: '2.5rem',
    columnGap: '1.5rem',
    justifyContent: 'center',
    alignItems: 'center',
  },
  btn: {
    paddingTop: '0.625rem',
    paddingBottom: '0.625rem',
    paddingLeft: '0.875rem',
    paddingRight: '0.875rem',
    borderRadius: '0.375rem',
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    fontWeight: 600,
    color: '#ffffff',
    backgroundColor: '#4F46E5',
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
    '&:hover': { backgroundColor: '#6366F1' },
  },
}));

const PageNotFound = () => {
  const classes = useStyles();

  const url = window.location.hostname.split('.')[1];
  const { port } = window.location;

  const href =
    url === `${config.LOCAL_URL}`
      ? `http://${config.LOCAL_URL}:${port}`
      : `https://${config.PUBLIC_URL}`;

  return (
    <Layout>
      <main className={classes.root}>
        <div className={classes.textCenter}>
          <p className={classes.paragraphTag}>404</p>
          <h1 className={classes.headerTag}>Page not found</h1>
          <p className={classes.paragraphTwoTag}>
            Sorry, we couldn&apos;t find the page you&apos;re looking for.
          </p>
          <div className={classes.btnContainer}>
            <div
              className={classes.btn}
              onClick={(e) => {
                e.preventDefault();
                window.location.replace(href);
              }}
            >
              Go back home
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default PageNotFound;
