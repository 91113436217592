import React from 'react';
import CustomContainer from '../../reuseable/CustomContainer';
import {
  containerConfig,
  videoConfig,
  titleConfig,
  textConfig,
  buttonConfig,
} from '../../propsConfig/index.js';
import CustomVideo from '../../reuseable/CustomVideo';
import CustomTitle from '../../reuseable/CustomTitle';
import CustomText from '../../reuseable/CustomText';
import CustomButton from '../../reuseable/CustomButton';

export default function Video({
  videoChild,
  titleChild,
  textChild,
  buttonChild,
  containerClassName,
  containerStyling,
  buttonsDirection,
}) {
  return (
    <CustomContainer {...containerConfig(containerClassName, containerStyling)}>
      {videoConfig(videoChild)?.map((videoBody) => (
        <CustomVideo key={videoBody.key} {...videoBody} />
      ))}
      <CustomTitle {...titleConfig(titleChild)} />
      <CustomText {...textConfig(textChild)} />
      {buttonConfig(buttonChild, buttonsDirection)?.map((buttonBody) => (
        <CustomButton key={buttonBody.key} {...buttonBody} />
      ))}
    </CustomContainer>
  );
}
