/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    borderRadius: '0.5rem',
    height: '210px',
    '@media (min-width: 540px)': { height: '157px' },
  },
  img: {
    position: 'absolute',
    borderRadius: '0.5rem',
    width: '100%',
    height: '100%',
  },
  containerTwo: {
    position: 'relative',
    height: '1.4rem',
    '@media (min-width: 540px)': {
      height: 'calc(1.4rem * 0.75)',
    },
  },
  imgTwo: {
    width: '100%',
    height: '100%',
  },
}));

export default function CustomImage({
  imageContainerProps,
  imageContainerClassName,
  imgProps,
  imageClassName,
  imageUrl,
  imageAlt,
}) {
  const classes = useStyles();
  return (
    <div
      {...imageContainerProps}
      className={`${imageContainerClassName} ${classes.container}`}
    >
      <img
        className={`${imageClassName} ${classes.img}`}
        style={{ objectFit: 'cover' }}
        src={imageUrl}
        alt={imageAlt}
        {...imgProps}
      />
    </div>
  );
}

export function CustomSocialsImage({
  imageContainerProps,
  imageContainerClassName,
  imgProps,
  imageClassName,
  imageUrl = '',
  imageAlt = '',
}) {
  const classes = useStyles();
  return (
    <div
      {...imageContainerProps}
      className={`${imageContainerClassName} ${classes.containerTwo}`}
    >
      <img
        className={`${imageClassName} ${classes.imgTwo}`}
        style={{ objectFit: 'cover' }}
        src={imageUrl}
        alt={imageAlt}
        {...imgProps}
      />
    </div>
  );
}
