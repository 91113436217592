/* eslint-disable no-nested-ternary */
const dev = {
  PUBLIC_API_URL: 'https://dev-bizi.tappshare.io',
};

const staging = {
  PUBLIC_API_URL: 'https://staging-bizi.tappshare.io',
};

const prod = {
  PUBLIC_API_URL: 'https://dev-bizi.tappshare.io',
};

const configUrl =
  process.env.REACT_APP_STAGE === 'dev'
    ? dev
    : process.env.REACT_APP_STAGE === 'staging'
    ? staging
    : prod;

export default configUrl;
