import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { ButtonContainer } from './Button';
import CustomContainer from '../../reuseable/CustomContainer';
import CustomTitle from '../../reuseable/CustomTitle';
import CustomButton from '../../reuseable/CustomButton';
import CustomPdf from '../../reuseable/CustomPdf';
import {
  buttonConfig,
  buttonContainerConfig,
  containerConfig,
  titleConfig,
} from '../../propsConfig';

const useStyles = makeStyles(() => ({
  container: {
    padding: '0 !important',
    gap: '0 !important',
    filter: 'unset !important',
    background: 'unset !important',
    border: 'unset !important',
  },
}));

export default function Pdf({
  pdfChild,
  titleChild,
  buttonsDirection,
  buttonChild,
  containerClassName,
  containerStyling,
}) {
  const classes = useStyles();

  const pdfConfig = {
    file: pdfChild[0].url,
    name: pdfChild[0].media_id,
  };

  return (
    <CustomContainer {...containerConfig(containerClassName, containerStyling)}>
      <CustomPdf {...pdfConfig} />
      <CustomTitle {...titleConfig(titleChild)} />
      <ButtonContainer
        {...buttonContainerConfig(
          classes.container,
          containerStyling,
          buttonsDirection,
        )}
      >
        {buttonConfig(buttonChild, buttonsDirection)?.map((buttonBody) => (
          <CustomButton {...buttonBody} />
        ))}
      </ButtonContainer>
    </CustomContainer>
  );
}
