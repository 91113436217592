/* eslint-disable import/no-extraneous-dependencies */
import React, { Suspense } from 'react';
import { QueryClient } from '@tanstack/react-query';
import { fetchBiziBio } from '../../api/bio';
import Loading from '../../components/loading/Loading';
import Default from './Default';
import config from '../../config';
import BiziBio from './BiziBio';

const Home = () => {
  const URL = window.location.href.split('//')[1];
  const PORT = window.location.port;

  const isVanityName = URL.split('.')[1] === 'isbizi';

  if (!isVanityName) return <Default />;

  if (
    URL === `${config.PUBLIC_URL}/` ||
    URL === `${config.LOCAL_URL}:${PORT}/`
  ) {
    return <Default />;
  }

  const vanityUrl = URL.split('.')[0];

  const queryClient = new QueryClient();

  queryClient.prefetchQuery({
    queryKey: ['bizi_bio', vanityUrl],
    queryFn: async () => {
      const data = await fetchBiziBio(vanityUrl);
      return data.result;
    },
  });

  return (
    <Suspense fallback={<Loading />}>
      <BiziBio {...{ vanityUrl }} />
    </Suspense>
  );
};

export default Home;
