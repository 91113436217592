import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { ButtonContainer } from './Button';
import CustomContainer from '../../reuseable/CustomContainer';
import CustomTitle from '../../reuseable/CustomTitle';
import CustomButton from '../../reuseable/CustomButton';
import CustomMap from '../../reuseable/CustomMap';
import {
  buttonConfig,
  buttonContainerConfig,
  containerConfig,
  titleConfig,
} from '../../propsConfig';

const useStyles = makeStyles(() => ({
  container: {
    padding: '0 !important',
    gap: '0 !important',
    filter: 'unset !important',
    background: 'unset !important',
    border: 'unset !important',
  },
}));

export default function Location({
  mapChild,
  titleChild,
  buttonsDirection,
  buttonChild,
  containerClassName,
  containerStyling,
}) {
  const classes = useStyles();

  const mapConfig = {
    address: mapChild.address,
    id: mapChild.id,
    latitude: mapChild.latitude,
    longitude: mapChild.longitude,
  };

  return (
    <CustomContainer {...containerConfig(containerClassName, containerStyling)}>
      <CustomMap {...mapConfig} />
      <CustomTitle {...titleConfig(titleChild)} />
      <ButtonContainer
        {...buttonContainerConfig(
          classes.container,
          containerStyling,
          buttonsDirection,
        )}
      >
        {buttonConfig(buttonChild, buttonsDirection)?.map((buttonBody) => (
          <CustomButton {...buttonBody} />
        ))}
      </ButtonContainer>
    </CustomContainer>
  );
}
