import React from 'react';
import CustomImage from '../../reuseable/CustomImage';
import CustomContainer from '../../reuseable/CustomContainer';
import CustomTitle from '../../reuseable/CustomTitle';
import CustomButton from '../../reuseable/CustomButton';
import {
  buttonConfig,
  containerConfig,
  imageConfig,
  titleConfig,
} from '../../propsConfig';

export default function Image({
  imageChild,
  titleChild,
  buttonChild,
  containerClassName,
  containerStyling,
  buttonsDirection,
}) {
  return (
    <CustomContainer {...containerConfig(containerClassName, containerStyling)}>
      {imageConfig(imageChild)?.map((imageBody) => (
        <CustomImage {...imageBody} />
      ))}
      <CustomTitle {...titleConfig(titleChild)} />
      {buttonConfig(buttonChild, buttonsDirection)?.map((buttonBody) => (
        <CustomButton {...buttonBody} />
      ))}
    </CustomContainer>
  );
}
