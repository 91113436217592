/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { moveAlphaToEnd } from '../../utils/hexColorFormat';

const useStyles = makeStyles(() => ({
  container: {
    borderRadius: '0.5rem',
    padding: '15px',
    '@media (min-width: 540px)': { padding: '10px' },
  },
}));

export default function CustomContainer({
  children,
  containerClassName,
  moreStylingProps,
  containerStyling,
}) {
  const classes = useStyles();
  const borderColor = moveAlphaToEnd(containerStyling?.borderColor || '');
  const backgroundColor = moveAlphaToEnd(
    containerStyling?.backgroundColor || '',
  );
  return (
    <section
      style={{
        border: containerStyling?.borderColor
          ? `1px solid ${borderColor}`
          : 'unset',
        backgroundColor,
        ...moreStylingProps,
      }}
      className={`${containerClassName} ${classes.container}`}
    >
      {children}
    </section>
  );
}
