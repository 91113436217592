/* eslint-disable react/button-has-type */
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { ButtonContainer } from '../microsite/elements/Button';
import { buttonContainerConfig, ctaButtonConfig } from '../propsConfig';
import CustomButton from '../reuseable/CustomButton';

const useStyles = makeStyles(() => ({
  root: {
    position: 'sticky',
    zIndex: 3,
    bottom: '20px',
  },
  container: {
    padding: '0 !important',
    gap: '0 !important',
    filter: 'unset !important',
    background: 'unset !important',
    border: 'unset !important',
  },
  buttonContainerClassName: {
    filter: 'drop-shadow(0 0 8px rgba(0, 0, 0, 0.12))',
  },
}));

export default function CTACard({
  saveCard,
  shareCard,
  containerClassName,
  containerStyling,
}) {
  const buttonChild = [
    {
      id: '1',
      destination: 'yes',
      order: 0,
      button_style: {
        color: 'FFEBE0E0',
        border_color: 'FFCE0A7D',
        radius: 12,
        size: 'half',
        alignment: 'center',
      },
      button_text: {
        value: 'Save',
        style: {
          alignment: 'center',
          color: 'FF000000',
          weight: 'bold',
          size: 15,
          italic: false,
          underline: false,
        },
      },
      onClick: () => saveCard(),
    },
    {
      id: '2',
      destination: 'no',
      order: 1,
      button_style: {
        color: 'FFCE0A7D',
        border_color: '00000000',
        radius: 12,
        size: 'half',
        alignment: 'center',
      },
      button_text: {
        value: 'Share',
        style: {
          alignment: 'center',
          color: 'FFFFFFFF',
          weight: 'bold',
          size: 15,
          italic: false,
          underline: false,
        },
      },
      onClick: () => shareCard(),
    },
  ];

  const classes = useStyles();
  const containerProps = `${classes.buttonContainerClassName} $classes.container} ${containerClassName}`;

  return (
    <section className={classes.root}>
      <ButtonContainer
        {...buttonContainerConfig(
          containerProps,
          containerStyling,
          'horizontal',
        )}
      >
        {ctaButtonConfig(buttonChild)?.map((buttonBody) => (
          <CustomButton {...buttonBody} />
        ))}
      </ButtonContainer>
    </section>
  );
}
