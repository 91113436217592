import React, { Suspense } from 'react';
import { QueryClient } from '@tanstack/react-query';
import Loading from '../../components/loading/Loading';
import BiziBusinessCard from './BiziBusinessCard';
import { fetchBusinessCard } from '../../api/businesscard';

export default function BusinessCardPage() {
  const { host } = window.location;
  const id = window.location.pathname.split('/')[2];
  const vanityName = host.split('.')[0];

  const queryClient = new QueryClient();

  queryClient.prefetchQuery({
    queryKey: ['business_card', id],
    queryFn: async () => {
      const data = await fetchBusinessCard(id, vanityName);
      return data.result;
    },
  });

  return (
    <Suspense fallback={<Loading />}>
      <BiziBusinessCard {...{ id }} />
    </Suspense>
  );
}
