import React from 'react';
import { makeStyles } from '@material-ui/styles';
import CustomContainer from '../../reuseable/CustomContainer';
import CustomTitle from '../../reuseable/CustomTitle';
import CustomText from '../../reuseable/CustomText';

const useStyles = makeStyles(() => ({
  dropShadow: {
    filter: 'drop-shadow(0 0 8px rgba(0, 0, 0, 0.12))',
  },
  mt9: {
    marginTop: '9px',
  },
}));

export default function Text({
  title,
  titleClassName,
  titleStyling = {
    fontSize: 17,
    fontWeight: 'bold',
    color: '#333333',
  },
  descr,
  descrClassName = useStyles.mt9,
  descrStyling = {
    fontSize: 13,
    color: '#333333',
  },
  containerClassName,
  containerStyling = {
    backgroundColor: '#fff',
  },
}) {
  const classes = useStyles();
  return (
    <section>
      <CustomContainer
        containerClassName={`${containerClassName} ${classes.dropShadow}`}
        containerStyling={containerStyling}
      >
        <CustomTitle
          title={title}
          titleClassName={titleClassName}
          titleStyling={titleStyling}
        />
        <CustomText
          text={descr}
          textClassName={descrClassName}
          textStyling={descrStyling}
        />
      </CustomContainer>
    </section>
  );
}
