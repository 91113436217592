/* eslint-disable import/prefer-default-export */
export function moveAlphaToEnd(colorFromServer) {
  let hex = '';
  if (colorFromServer.startsWith('#')) {
    hex = colorFromServer.substring(1);
  } else {
    hex = colorFromServer;
  }

  const hexPatternRGBA = /^([A-Fa-f0-9]{8})$/;
  const hexPatternRGB = /^([A-Fa-f0-9]{6})$/;

  if (!hexPatternRGBA.test(hex)) {
    if (!hexPatternRGB.test(hex)) {
      return '#FFFFFFFF';
    }
    return `#${hex}`;
  }
  const alpha = hex.slice(0, 2);
  const color = hex.slice(2);

  const newHex = `#${color}${alpha}`;
  return newHex;
}
