import React from 'react';
import { useTheme } from '@material-ui/styles';
import { MdModeNight } from 'react-icons/md';
import { WiDaySunny } from 'react-icons/wi';
import { IconButton } from '@mui/material';
import ColorModeContext from '../../config/color-context';

export default function ThemeToggle() {
  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);

  const textTheme = theme.palette.text.default;

  return (
    <IconButton
      sx={{
        display: 'none',
        alignItems: 'center',
        cursor: 'pointer',
        position: 'absolute',
        top: '1rem',
        right: '1rem',
        border: `1px solid ${textTheme}`,
        backdropFilter: 'blur(8px)',
        '@media (min-width: 540px)': {
          display: 'inline-flex',
        },
      }}
      onClick={colorMode.toggleColorMode}
      color="inherit"
    >
      {theme.palette.mode === 'dark' ? (
        <MdModeNight color={textTheme} />
      ) : (
        <WiDaySunny />
      )}
    </IconButton>
  );
}
