import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import mapboxgl from 'mapbox-gl';
import config from '../../config';

mapboxgl.accessToken = config.MAPBOX_ACCESS_TOKEN;

// const ANIMATE_DURATATION = 1000;
export default function CustomMap(geoLocation) {
  const [loadingMap] = React.useState(!geoLocation && false);

  const [map, setMap] = React.useState(null);
  const mapContainer = React.useRef(null);

  //   const onZoomIn = () => {
  //     map.zoomIn({ duration: ANIMATE_DURATATION });
  //   };

  //   const onZoomOut = () => {
  //     map.zoomOut({ duration: ANIMATE_DURATATION });
  //   };

  const loadMap = (data) => {
    if (!map && mapContainer.current) {
      const newMap = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [0, 0],
        zoom: 11,
        attributionControl: false,
      });
      newMap.on('load', () => {
        newMap.addSource('engagments', {
          type: 'geojson',
          data: {
            features: [
              {
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: [data.longitude, data.latitude],
                },
              },
            ],
          },
          cluster: true,
          clusterMaxZoom: 14,
          clusterRadius: 30,
        });

        newMap.addLayer({
          id: 'clusters',
          type: 'circle',
          source: 'engagments',
          filter: ['has', 'point_count'],
          paint: {
            'circle-color': [
              'step',
              ['get', 'point_count'],
              '#70b4ff',
              100,
              '#2085FF',
              750,
              '#0059cb',
            ],
            'circle-radius': [
              'step',
              ['get', 'point_count'],
              10,
              100,
              15,
              750,
              20,
            ],
          },
        });

        newMap.addLayer({
          id: 'cluster-count',
          type: 'symbol',
          source: 'engagments',
          filter: ['has', 'point_count'],
          layout: {
            'text-field': '{point_count_abbreviated}',
            'text-size': 12,
          },
        });

        newMap.addLayer({
          id: 'unclustered-point',
          type: 'circle',
          source: 'engagments',
          filter: ['!', ['has', 'point_count']],
          paint: {
            'circle-color': '#2085FF',
            'circle-radius': 4,
          },
        });

        newMap.jumpTo({ center: [data.longitude, data.latitude], zoom: 11 });
      });
      setMap(newMap);
    }
  };

  React.useEffect(() => {
    if (geoLocation && !loadingMap) {
      loadMap(geoLocation);
    }
  }, [geoLocation]);

  return (
    <Box sx={{ borderRadius: '100%' }}>
      <Box
        position="relative"
        display="flex"
        flexDirection="column"
        borderRadius={50}
        sx={{
          maxWidth: '100%',
          height: 210,
          '@media (min-width: 540px)': {
            height: '157px',
          },
        }}
      >
        <Box
          sx={{
            '& .mapboxgl-canvas-container': {},
            '& .mapboxgl-canvas': {
              width: '100% !Important',
              maxHeight: '210px',
              height: '210px',
              borderRadius: '6px',
            },
            '& .mapboxgl-canvas:focus': {
              outline: 'none',
            },
            '@media (min-width: 540px)': {
              '& .mapboxgl-canvas': {
                maxHeight: '157px',
                height: '157px',
              },
            },
          }}
          width="100%"
          flex={1}
          overflow="hidden"
          m="8px auto 0 auto"
          ref={
            (el) => (mapContainer.current = el) // eslint-disable-line
          }
        >
          {loadingMap && (
            <CircularProgress
              sx={{ position: 'absolute', left: '50%', top: '50%' }}
            />
          )}
        </Box>
        {/* <ZoomControl
    sx={{
      position: 'absolute',
      left: 8,
      bottom: 0,
      zIndex: 2,
      '@media (max-device-width: 959px)': {
        bottom: '40px',
      },
    }}
    onZoomIn={onZoomIn}
    onZoomOut={onZoomOut}
  /> */}
      </Box>
    </Box>
  );
}
