/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import ReactDOM from 'react-dom';
import { CookiesProvider } from 'react-cookie';
import App from './App';

const renderMethod = module.hot ? ReactDOM.render : ReactDOM.hydrate;

renderMethod(
  <CookiesProvider>
    <App />
  </CookiesProvider>,
  document.getElementById('root') || document.createElement('div'),
);

// serviceWorker.unregister();
