import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { ButtonContainer } from './Button';
import CustomContainer from '../../reuseable/CustomContainer';
import CustomAudio from '../../reuseable/CustomAudio';
import CustomTitle from '../../reuseable/CustomTitle';
import CustomButton from '../../reuseable/CustomButton';
import {
  buttonConfig,
  buttonContainerConfig,
  containerConfig,
  soundConfig,
  titleConfig,
} from '../../propsConfig';

const useStyles = makeStyles(() => ({
  container: {
    padding: '0 !important',
    gap: '0 !important',
    filter: 'unset !important',
    background: 'unset !important',
    border: 'unset !important',
  },
}));

export default function Sound({
  soundChild,
  titleChild,
  buttonsDirection,
  buttonChild,
  containerClassName,
  containerStyling,
}) {
  const classes = useStyles();

  return (
    <CustomContainer {...containerConfig(containerClassName, containerStyling)}>
      {soundConfig(soundChild)?.map((soundBody) => (
        <CustomAudio {...soundBody} />
      ))}
      <CustomTitle {...titleConfig(titleChild)} />
      <ButtonContainer
        {...buttonContainerConfig(
          classes.container,
          containerStyling,
          buttonsDirection,
        )}
      >
        {buttonConfig(buttonChild, buttonsDirection)?.map((buttonBody) => (
          <CustomButton {...buttonBody} />
        ))}
      </ButtonContainer>
    </CustomContainer>
  );
}
