/* eslint-disable import/prefer-default-export */
import { axiosClient } from './client';

export const fetchMicrosite = async (pathname, vanityName) => {
  const response = await axiosClient.get(
    `/microsite/${pathname}?vanity=${vanityName}`,
  );
  const result = response.data;

  return result;
};
