/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/button-has-type */
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@mui/material';
import { PDFIcon } from '../icons/ReactIcons';

const useStyles = makeStyles(() => ({
  pdf: {
    position: 'relative',
    width: '100%',
    height: '150px',
    overflowY: 'scroll',
    '@media (min-width: 540px)': {
      height: `calc(150px * 0.78)`,
    },
  },
  button: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '150px',
    marginBottom: '5px',
    '@media (min-width: 540px)': {
      height: `calc(150px * 0.78)`,
    },
  },
  pdfIcon: {
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50px',
    height: '50px',
    '@media (min-width: 540px)': {
      width: `calc(50px * 0.78)`,
      height: `calc(50px * 0.78)`,
    },
  },
  pdfName: {
    fontFamily: 'Poppins',
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '19px',
    textAlign: 'center',
    color: '#282A37',
    '@media (min-width: 540px)': {
      fontSize: `calc(13px * 0.78)`,
    },
  },
  pdfContainer: {
    display: 'flex',
    justifyContent: 'center',
    userSelect: 'none',
  },
  controls: {
    margin: '20px',
    '@media (min-width: 540px)': {
      margin: `calc(20px * 0.78)`,
    },
  },
  style: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  },
}));

export default function CustomPdf({ file }) {
  const classes = useStyles();

  const handleOpen = () => {
    window.open(
      `https://docs.google.com/gview?url=${file}&embedded=true`,
      '_blank',
    );
  };

  return (
    <div>
      <Button className={classes.button} onClick={handleOpen}>
        <PDFIcon className={classes.pdfIcon} />
        <object
          className={classes.pdf}
          type="application/pdf"
          data={`https://docs.google.com/gview?url=${file}&embedded=true`}
        >
          <p style={{ fontSize: '12px' }}>Cannot display pdf</p>
        </object>
      </Button>
    </div>
  );
}
