import React from 'react';
import { Box, CircularProgress } from '@mui/material';

export default function Loading() {
  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '@media (min-width: 540px)': { height: '100%' },
      }}
    >
      <CircularProgress
        sx={{
          color: '#CE0A7D',
        }}
        size={60}
        thickness={4}
        color="secondary"
      />
    </Box>
  );
}
