/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { BsDashLg } from 'react-icons/bs';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
// import { OutlinedInput } from '@mui/material';
import { FaLocationDot } from 'react-icons/fa6';
import { IoMail } from 'react-icons/io5';
import { MdPhone } from 'react-icons/md';
import { makeStyles } from '@material-ui/styles';
import { Box } from '@mui/material';
import ProfileIcon from '../../assets/icons/user_round.svg';

const useStyles = makeStyles(() => ({
  cardContainer: {
    padding: '1rem',
    borderRadius: '1.5rem',
    width: '100%',
    color: '#ffffff',
    minHeight: '212.11px',
    background: 'linear-gradient(106.16deg, #CE0A7D -30.78%, #3369FF 116.38%)',
    '@media (min-width: 540px)': {
      padding: 'calc(0.78 * 1rem)',
      borderRadius: 'calc(0.78 * 1.5rem)',
      minHeight: 'calc(0.78 * 212.11px)',
    },
  },
  labelWrapper: {
    paddingTop: '0.375rem',
    paddingBottom: '0.375rem',
    fontWeight: 500,
    textTransform: 'uppercase',
    fontSize: '16px',
    lineHeight: '17.7px',
    '@media (min-width: 540px)': {
      paddingTop: 'calc(0.78 * 0.375rem)',
      paddingBottom: 'calc(0.78 * 0.375rem)',
      fontSize: 'calc(0.78 * 16px)',
      lineHeight: 'calc(0.78 * 17.7px)',
    },
  },
  detailsContainer: {
    display: 'flex',
    marginTop: '18px',
    gap: '19px',
    '@media (min-width: 540px)': {
      marginTop: 'calc(0.78 * 18px)',
      gap: 'calc(0.78 * 19px)',
    },
  },
  imgContainer: {
    position: 'relative',
    borderRadius: '9999px',
    width: '80px',
    height: '80px',
    '@media (min-width: 540px)': {
      width: 'calc(0.78 * 80px)',
      height: 'calc(0.78 * 80px)',
    },
  },
  img: {
    position: 'absolute',
    borderRadius: '9999px',
    width: '100%',
    height: '100%',
  },
  nameTag: {
    width: '90%',
    fontWeight: 700,
    textTransform: 'capitalize',
    marginTop: '6.4px',
    fontSize: '22px',
    lineHeight: '28px',
    '@media (min-width: 540px)': {
      marginTop: 'calc(0.78 * 6.4px)',
      fontSize: 'calc(0.78 * 22px)',
      lineHeight: 'calc(0.78 * 28px)',
    },
  },
  designationContainer: {
    display: 'flex',
    gap: '0.15rem',
    alignItems: 'center',
    '@media (min-width: 540px)': {
      gap: 'calc(0.78 * 0.15rem)',
    },
  },
  designationTag: {
    fontWeight: 500,
    textTransform: 'capitalize',
    marginTop: '6.4px',
    fontSize: '14px',
    lineHeight: '21px',
    '@media (min-width: 540px)': {
      marginTop: 'calc(0.78 * 6.4px)',
      fontSize: 'calc(0.78 * 14px)',
      lineHeight: 'calc(0.78 * 21px)',
    },
  },
  h8: {
    height: '2rem',
    width: '30px',
    marginTop: '7px',
    '@media (min-width: 540px)': {
      height: 'calc(0.78 * 2rem)',
      marginTop: '4px',
    },
  },
  moreTag: {
    fontWeight: 500,
    textTransform: 'capitalize',
    marginTop: '6.4px',
    fontSize: '12px',
    lineHeight: '15px',
    width: '80%',
    '@media (min-width: 540px)': {
      marginTop: 'calc(0.78 * 6.4px)',
      fontSize: 'calc(0.78 * 12px)',
      lineHeight: 'calc(0.78 * 15px)',
    },
  },
  hr: {
    backgroundColor: '#ffffff',
    marginTop: '15.54px',
    marginBottom: '15.54px',
    '@media (min-width: 540px)': {
      marginTop: 'calc(0.78 * 15.54px)',
      marginBottom: 'calc(0.78 * 15.54px)',
    },
  },
  container: {
    display: 'flex',
    paddingLeft: '3px',
    paddingRight: '3px',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media (min-width: 540px)': {
      paddingLeft: 'calc(0.78 * 3px)',
      paddingRight: 'calc(0.78 * 3px)',
    },
  },
  iconOne: {
    height: '20px',
    width: '30px',
    '@media (min-width: 540px)': {
      height: 'calc(0.78 * 20px)',
    },
  },
  iconTwo: {
    height: '22px',
    width: '30px',
    '@media (min-width: 540px)': {
      height: 'calc(0.78 * 22px)',
    },
  },
  mr6: {
    marginRight: '6px',
    '@media (min-width: 540px)': {
      marginRight: 'calc(0.78 * 6px)',
    },
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '95%',
  },
  iconContainerTwo: {
    display: 'flex',
    alignItems: 'center',
    width: '48%',
  },
  rightBtm: {
    marginLeft: 'auto',
  },
  fontSize: {
    fontSize: '14px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    '@media (min-width: 540px)': {
      fontSize: 'calc(0.78 * 14px)',
    },
  },
  ellipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

export default function CustomBusinessCard({
  business_address,
  designation,
  email,
  media,
  name,
  phone,
  type,
}) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.cardContainer}>
        <div className={classes.labelWrapper}>
          <label>{type}</label>
        </div>
        <div className={classes.detailsContainer}>
          <div className={classes.imgContainer}>
            <img
              className={classes.img}
              src={media ? media?.url : ProfileIcon}
              style={{ objectFit: 'cover' }}
              alt={media?.media_id}
            />
          </div>
          <Box sx={{ width: '70%' }} className="details">
            {name && (
              <div className={`${classes.nameTag}`}>
                <div className={classes.ellipsis}>{name}</div>
              </div>
            )}
            {designation && (
              <div
                className={`${classes.designationContainer} ${classes.iconContainer}`}
              >
                <BsDashLg className={classes.h8} />
                <div
                  className={`${classes.designationTag} ${classes.ellipsis}`}
                >
                  {designation}
                </div>
              </div>
            )}
            {business_address && (
              <div className={`${classes.moreTag} ${classes.iconContainer}`}>
                <FaLocationDot
                  className={`${classes.iconOne} ${classes.mr6}`}
                />
                <div className={`${classes.ellipsis}`}>
                  {typeof business_address === 'string' ? (
                    business_address
                  ) : (
                    <>
                      {business_address.address_line_1 && (
                        <span>{business_address.address_line_1}, </span>
                      )}
                      {business_address.address_line_2 && (
                        <span>{business_address.address_line_2}, </span>
                      )}
                      {business_address.city && (
                        <span>{business_address.city}, </span>
                      )}
                      {business_address.state && (
                        <span>{business_address.state}, </span>
                      )}
                      {business_address.postal_code && (
                        <span>{business_address.postal_code}, </span>
                      )}
                      {business_address.country && (
                        <span>{business_address.country}</span>
                      )}
                    </>
                  )}
                </div>
              </div>
            )}
          </Box>
        </div>
        <hr className={classes.hr} />
        <div className={classes.container}>
          {email && (
            <div className={classes.iconContainerTwo}>
              <IoMail className={`${classes.iconTwo} ${classes.mr6}`} />
              <div className={classes.fontSize}>{email}</div>
            </div>
          )}
          {phone && (
            <div className={classes.iconContainerTwo}>
              <MdPhone
                className={`${classes.iconTwo} ${classes.mr6} ${classes.rightBtm}`}
              />
              <div className={classes.fontSize}>{phone}</div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
