/* eslint-disable global-require */
/* eslint-disable react/button-has-type */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { makeStyles } from '@material-ui/styles';
import { Alert, Box, IconButton } from '@mui/material';
import { IoClose } from 'react-icons/io5';
import Loading from '../../components/loading/Loading';
import ContentLayout from '../../components/layout/ContentLayout';
import BusinessCard from '../../components/microsite/elements/BusinessCard';
import PageNotFound from '../404';
import ErrorPage from '../error/Error';
import CTACard from '../../components/business_card/CTACard';
import { fetchBusinessCard } from '../../api/businesscard';

const useStyles = makeStyles(() => ({
  root: {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '32rem',
    height: '100%',
    position: 'relative',
  },
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: '20px',
    gap: '24px',
    justifyContent: 'space-between',
    '@media (min-width: 540px)': {
      padding: 'calc(20px * 0.6)',
      gap: 'calc(24px * 0.75)',
    },
  },
}));

export default function BiziBusinessCard({ id }) {
  const { host } = window.location;
  const vanityName = host.split('.')[0];

  const [show, setShow] = useState(false);

  const classes = useStyles();

  const { isLoading, isError, data, error } = useQuery({
    queryKey: ['business_card', id],
    queryFn: async () => {
      const dataBody = await fetchBusinessCard(id, vanityName);
      return dataBody.result;
    },
    enabled: Boolean(id),
    refetchOnWindowFocus: false,
  });

  if (isError) {
    if (error.message.includes('404')) {
      return <PageNotFound />;
    }
    return <ErrorPage error={error} />;
  }

  const CreateVCard = () => {
    const vCardsJS = require('vcards-js');
    const FileSaver = require('file-saver');

    const vCard = vCardsJS();
    if (data) {
      const firstName = data?.name.split(' ').slice(0, -1).join(' ');
      const lastName = data?.name.split(' ').slice(-1).join(' ');
      const vcardUrl = data?.type === 'business' ? 'workUrl' : 'url';
      const vcardHomeAddress =
        data?.type === 'business' ? 'workAddress' : 'homeAddress';
      // set properties
      vCard.firstName = firstName;
      vCard.lastName = lastName;
      vCard.email = data?.email;
      vCard.uid = data?.id;
      vCard.organization = data?.business_type;
      vCard.photo.attachFromUrl(data.url);
      vCard.workPhone = data?.phone.country_code + data?.phone.phone_number;
      vCard.title = data?.designation;
      vCard[vcardUrl] = data?.website;
      vCard[vcardHomeAddress].label =
        data?.type === 'business' ? 'Work Address' : 'Home Address';
      vCard[vcardHomeAddress].street = data?.business_address;
      vCard.note = data?.about;

      // save to file
      const blob = new Blob([vCard.getFormattedString()], {
        type: 'text/vcard;charset=utf-8',
      });

      FileSaver.saveAs(blob, `${data?.name}.vcf`);
    }
  };
  const copyToClipboard = () => {
    navigator.clipboard.writeText(window.location.href);
    setShow(true);
  };

  return (
    <ContentLayout
      style={{
        backgroundColor: '#FFFFFF',
      }}
    >
      {isLoading ? (
        <Loading />
      ) : (
        data && (
          <div className={classes.root}>
            <div className={classes.container}>
              <BusinessCard cardChild={data} />
              <Message {...{ show, setShow }} />
              <CTACard saveCard={CreateVCard} shareCard={copyToClipboard} />
            </div>
          </div>
        )
      )}
    </ContentLayout>
  );
}

const Message = ({ show, setShow }) => {
  useEffect(() => {
    const timeId = setTimeout(() => {
      setShow(false);
    }, 3000);

    return () => {
      clearTimeout(timeId);
    };
  }, [show]);

  if (!show) {
    return null;
  }

  return (
    <Box
      sx={{
        position: 'absolute',
        width: '100%',
        zIndex: 100,
        top: '5px',
        left: 0,
      }}
    >
      <Alert
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setShow(false);
            }}
          >
            <IoClose fontSize="inherit" />
          </IconButton>
        }
        icon={false}
        severity="success"
      >
        Link Copied
      </Alert>
    </Box>
  );
};
