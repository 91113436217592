/* eslint-disable prefer-template */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { makeStyles } from '@material-ui/styles';
import { fetchBiziBio } from '../../api/bio';
import Loading from '../../components/loading/Loading';
import ContentLayout from '../../components/layout/ContentLayout';
import BiziBioCard from '../../components/microsite/elements/BiziBioCard';
import { moveAlphaToEnd } from '../../utils/hexColorFormat';
import PageNotFound from '../404';
import ErrorPage from '../error/Error';

export default function BiziBio({ vanityUrl }) {
  const { isLoading, isError, data, error } = useQuery({
    queryKey: ['bizi_bio', vanityUrl],
    queryFn: async () => {
      const dataBody = await fetchBiziBio(vanityUrl);
      return dataBody.result;
    },
    enabled: Boolean(vanityUrl),
  });

  const useStyles = makeStyles(() => ({
    root: {
      marginLeft: 'auto',
      marginRight: 'auto',
      // paddingBottom: '5rem',
      maxWidth: '32rem',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      padding: '20px',
      gap: '24px',
      overflowY: 'auto',
      scrollBehavior: 'smooth',
      backgroundColor: moveAlphaToEnd(data?.style?.background_color || ''),
      '@media (min-width: 540px)': {
        padding: 'calc(20px * 0.6)',
        paddingBottom: 'calc(30px * 0.6)',
        gap: 'calc(24px * 0.75)',
        height: '640px',
      },
    },
  }));

  const classes = useStyles();

  if (isError) {
    if (error.message.includes('404')) {
      return <PageNotFound />;
    }
    return <ErrorPage error={error} />;
  }

  return (
    <ContentLayout>
      {isLoading ? (
        <Loading />
      ) : (
        data && (
          <div className={classes.root}>
            <div className={classes.container}>
              <BiziBioCard
                bioChild={data}
                containerStyling={{
                  backgroundColor: data.style?.background_color,
                }}
              />
            </div>
          </div>
        )
      )}
    </ContentLayout>
  );
}
