/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/button-has-type */
/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { RiReplay10Fill, RiForward10Fill } from 'react-icons/ri';
import { IoPlayCircle, IoStopCircle } from 'react-icons/io5';
import { BsSoundwave } from 'react-icons/bs';
import { MdReplayCircleFilled } from 'react-icons/md';
import { IoMdVolumeHigh, IoMdVolumeOff, IoMdVolumeLow } from 'react-icons/io';
import './audio.css';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  progressBar: {
    display: 'flex',
    marginLeft: '0.5rem',
    marginRight: '0.5rem',
    width: '100%',
    '@media (min-width: 540px)': {
      marginLeft: 'calc(0.5rem * 0.78)',
      marginRight: 'calc(0.5rem * 0.78)',
    },
  },
  controlsWrapper: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '9999px',
    border: '2px solid #CE0A7D',
    width: '100%',
  },
  controls: {
    display: 'flex',
    height: '45px',
    paddingLeft: '1.3rem',
    gap: '0.5rem',
    alignItems: 'center',
    '@media (min-width: 540px)': {
      height: 'calc(45px * 0.8)',
      // paddingRight: 'calc(1rem * 0.78)',
      paddingLeft: 'calc(1.3rem * 0.78)',
      gap: 'calc(0.5rem * 0.78)',
    },
  },
  iconOne: {
    flex: 'none',
    marginTop: '0.34rem',
    width: '1.2rem',
    height: '1.2rem',
    color: 'grey',
    '@media (min-width: 540px)': {
      width: 'calc(1.2rem * 0.78)',
      height: 'calc(1.2rem * 0.78)',
    },
  },
  iconTwo: {
    flex: 'none',
    marginTop: '0.34rem',
    width: '1.5rem',
    height: '1.5rem',
    '@media (min-width: 540px)': {
      width: 'calc(1.5rem * 0.78)',
      height: 'calc(1.5rem * 0.78)',
    },
  },
  iconMain: {
    flex: 'none',
    marginTop: '0.30rem',
    width: '2rem',
    height: '2rem',
    '@media (min-width: 540px)': {
      width: 'calc(2rem * 0.78)',
      height: 'calc(2rem * 0.78)',
    },
  },
  iconThree: {
    flex: 'none',
    width: '1.25rem',
    height: '1.25rem',
    '@media (min-width: 540px)': {
      width: 'calc(1.25rem * 0.78)',
      height: 'calc(1.25rem * 0.78)',
    },
  },
  buttonColor: { color: '#CE0A7D' },
  childContainer: {
    display: 'flex',
    position: 'relative',
    flex: '1 1 auto',
    alignItems: 'center',
  },
  mr5: {
    marginRight: '1.25rem',
    '@media (min-width: 540px)': {
      marginRight: 'calc(1.25rem * 0.78)',
    },
  },
  mr1: {
    marginRight: '0.25rem',
    '@media (min-width: 540px)': {
      marginRight: 'calc(0.25rem * 0.78)',
    },
  },
  volume: {
    display: 'flex',
    position: 'absolute',
    top: '-0.5rem',
    right: '-35%',
    alignItems: 'center',
  },
}));

export default function CustomAudio({ audioUrl }) {
  const [duration, setDuration] = useState(0);
  const [replay, setReplay] = useState(false);

  const audioRef = useRef();
  const progressBarRef = useRef();

  const handleReplay = () => {
    setReplay(true);
  };

  return (
    <div>
      <DisplayTrack
        {...{
          currentTrack: audioUrl,
          audioRef,
          setDuration,
          progressBarRef,
          handleReplay,
        }}
      />
      <Controls
        {...{
          audioRef,
          progressBarRef,
          duration,
          replay,
          setReplay,
        }}
      >
        <ProgressBar {...{ progressBarRef, audioRef, duration }} />
      </Controls>
    </div>
  );
}

const DisplayTrack = ({
  currentTrack,
  audioRef,
  setDuration,
  progressBarRef,
  handleReplay,
}) => {
  useEffect(() => {
    const seconds = Math.floor(audioRef.current.duration);
    setDuration(seconds);
    progressBarRef.current.max = seconds;
  }, [audioRef?.current?.loadedmetadata, audioRef?.current?.readyState]);

  return <audio src={currentTrack} ref={audioRef} onEnded={handleReplay} />;
};

const ProgressBar = ({ progressBarRef, audioRef, duration }) => {
  const classes = useStyles();

  const handleProgressChange = () => {
    audioRef.current.currentTime = Number(progressBarRef.current.value);
    changePlayerCurrentTime();
  };

  const changePlayerCurrentTime = () => {
    progressBarRef.current.style.setProperty(
      '--seek-before-width',
      `${(Number(progressBarRef.current.value) / duration) * 100}%`,
    );
  };

  return (
    <div className={classes.progressBar}>
      <input
        type="range"
        defaultValue="0"
        ref={progressBarRef}
        onChange={handleProgressChange}
      />
    </div>
  );
};

const Controls = ({
  children,
  audioRef,
  progressBarRef,
  duration,
  replay,
  setReplay,
}) => {
  const classes = useStyles();

  const [isPlaying, setIsPlaying] = useState(false);
  const [toggleVol, setToggleVol] = useState(false);
  const [volume, setVolume] = useState(60);
  const [muteVolume, setMuteVolume] = useState(false);

  const playAnimationRef = useRef();

  const togglePlayPause = () => {
    setIsPlaying((prev) => !prev);
  };

  const repeat = useCallback(() => {
    const currentTime = audioRef.current.currentTime;
    progressBarRef.current.value = currentTime;
    progressBarRef.current.style.setProperty(
      '--range-progress',
      `${(progressBarRef.current.value / duration) * 100}%`,
    );

    playAnimationRef.current = requestAnimationFrame(repeat);
  }, [audioRef, duration, progressBarRef]);

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
    playAnimationRef.current = requestAnimationFrame(repeat);
  }, [isPlaying, audioRef, repeat]);

  const skipForward = () => {
    audioRef.current.currentTime += 10;
  };

  const skipBackward = () => {
    audioRef.current.currentTime -= 10;
  };

  useEffect(() => {
    if (audioRef) {
      audioRef.current.volume = volume / 100;
      audioRef.current.muted = muteVolume;
    }
  }, [volume, audioRef, muteVolume]);

  return (
    <div className={`controls-wrapper ${classes.controlsWrapper}`}>
      <div className={`controls ${classes.controls}`}>
        <div onClick={skipBackward}>
          <RiReplay10Fill className={classes.iconOne} />
        </div>
        {replay === true ? (
          <div
            className={classes.buttonColor}
            onClick={() => {
              audioRef.current.play();
              setReplay(false);
            }}
          >
            <MdReplayCircleFilled className={classes.iconTwo} />
          </div>
        ) : (
          <div className={classes.buttonColor} onClick={togglePlayPause}>
            {isPlaying ? (
              <IoStopCircle className={classes.iconMain} />
            ) : (
              <IoPlayCircle className={classes.iconMain} />
            )}
          </div>
        )}

        <div onClick={skipForward}>
          <RiForward10Fill className={classes.iconOne} />
        </div>
      </div>
      <div className={classes.childContainer}>
        {children}
        <div onClick={() => setToggleVol(!toggleVol)}>
          <BsSoundwave
            className={`${classes.iconTwo} ${classes.buttonColor} ${classes.mr5}`}
          />
        </div>
        {toggleVol && (
          <div className={classes.volume}>
            <div
              className={classes.mr1}
              onClick={() => setMuteVolume((prev) => !prev)}
            >
              {muteVolume || volume < 5 ? (
                <IoMdVolumeOff className={classes.iconThree} />
              ) : volume < 40 ? (
                <IoMdVolumeLow className={classes.iconThree} />
              ) : (
                <IoMdVolumeHigh className={classes.iconThree} />
              )}
            </div>
            <input
              type="range"
              id="volume"
              min={0}
              max={100}
              value={volume}
              onChange={(e) => setVolume(Number(e.target.value))}
            />
          </div>
        )}
      </div>
    </div>
  );
};
