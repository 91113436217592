import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  childrenContainer: {
    height: '100%',
    borderColor: '#9CA3AF',
    '@media (min-width: 540px)': { display: 'none' },
  },
}));

export default function MobileView({ props }) {
  const classes = useStyles();

  return (
    <div {...props} className={classes.childrenContainer}>
      {props.children}
    </div>
  );
}
